import { ChakraBaseProvider } from '@chakra-ui/react';
import { TransactionsToastList, NotificationModal, SignTransactionsModals } from '@multiversx/sdk-dapp/UI';
import { Routes, Route, Navigate } from 'react-router-dom';
import Layout from './components/Layout';
import { theme } from './theme';
import Close from './components/Close';

function App() {
    return (
        <ChakraBaseProvider theme={theme}>
            <TransactionsToastList successfulToastLifetime={5000} transactionToastClassName="Tx-Toast" />
            <NotificationModal />
            <SignTransactionsModals className="Sign-Tx-Modal" />

            <Routes>
                <Route path="/:userId/:username/:avatar" element={<Layout />} />
                <Route path="/" element={<Close />} />

                <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
        </ChakraBaseProvider>
    );
}

export default App;
