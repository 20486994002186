import { Text, Center } from '@chakra-ui/react';

const Close = () => {
    return (
        <Center height="100vh" p={5}>
            <Text fontWeight={500} textShadow="0 0 4px rgb(0 0 0 / 30%)" lineHeight="20px" textAlign="center">
                Please close this page and click the{' '}
                <Text as="span" color="discord" fontWeight={600}>
                    Verify
                </Text>{' '}
                button again in Discord.
            </Text>
        </Center>
    );
};

export default Close;
