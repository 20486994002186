import { Text, Stack, Center, Spinner } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { DiscordUser } from '../shared/DiscordUser';
import Wallet from '../shared/Wallet';
import { logout } from '@multiversx/sdk-dapp/utils';
import axios from 'axios';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';
import { HOMEX_API } from '../blockchain/config';

const Link = (props: { userId?: string; username?: string; avatar?: string }) => {
    const { address } = useGetAccountInfo();

    const [isLoading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);

    useEffect(() => {
        const { userId } = props;

        if (userId) {
            save(userId);
        } else {
            setError(true);
            setLoading(false);
        }
    }, [props]);

    const save = async (userId: string) => {
        try {
            const response = await axios.post(
                `${HOMEX_API}/user`,
                { userId, address },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            console.log(response.data);
        } catch (error) {
            // Handle errors
            console.error(error);
        }

        setLoading(false);
    };

    return (
        <Center height="100vh" p={5}>
            {isLoading ? (
                <Spinner />
            ) : (
                <>
                    {!error ? (
                        <Stack spacing={8} alignItems="center">
                            <Stack spacing={4} alignItems="center">
                                <Text
                                    fontWeight={500}
                                    textShadow="0 0 4px rgb(0 0 0 / 30%)"
                                    lineHeight="20px"
                                    textAlign="center"
                                >
                                    Your wallet has been linked.
                                    <br />
                                    Please close this page and return to{' '}
                                    <Text as="span" color="discord" fontWeight={600}>
                                        Discord
                                    </Text>
                                    .
                                </Text>

                                {!props ? (
                                    <Spinner />
                                ) : (
                                    <Stack spacing={2} direction="row">
                                        <DiscordUser {...props} />
                                        <Wallet />
                                    </Stack>
                                )}

                                {/* TODO: */}
                                {/* <Text onClick={() => logout('/')}>Disconnect</Text> */}
                            </Stack>
                        </Stack>
                    ) : (
                        <Text fontWeight={500} textShadow="0 0 4px rgb(0 0 0 / 30%)" textAlign="center">
                            An error has occured.
                            <br />
                            Please close this page and return to{' '}
                            <Text as="span" color="discord" fontWeight={600}>
                                Discord
                            </Text>
                            .
                        </Text>
                    )}
                </>
            )}
        </Center>
    );
};

export default Link;
