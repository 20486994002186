import { useParams } from 'react-router-dom';
import Unlock from './Unlock';
import { useGetLoginInfo } from '@multiversx/sdk-dapp/hooks';
import Link from './Link';

function Layout() {
    const { isLoggedIn } = useGetLoginInfo();

    let { userId, username, avatar } = useParams();

    return isLoggedIn ? <Link {...{ userId, username, avatar }} /> : <Unlock {...{ userId, username, avatar }} />;
}

export default Layout;
