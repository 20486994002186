import { FunctionComponent, PropsWithChildren } from 'react';
import { Stack, Image, Text } from '@chakra-ui/react';

export const DiscordUser: FunctionComponent<
    PropsWithChildren<{
        userId?: string;
        username?: string;
        avatar?: string;
    }>
> = ({ userId, username, avatar }) => {
    return (
        <Stack direction="row" alignItems="center" backgroundColor="#272727" px={3} py={1} borderRadius="18px">
            <Image
                src={`https://cdn.discordapp.com/avatars/${userId}/${avatar}.png`}
                alt="Avatar"
                borderRadius="50%"
                w="28px"
            />
            <Text>{username}</Text>
        </Stack>
    );
};
