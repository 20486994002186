import {
    ExtensionLoginButton,
    WebWalletLoginButton,
    LedgerLoginButton,
    WalletConnectLoginButton,
} from '@multiversx/sdk-dapp/UI';
import { Box, Text, Flex, Stack, Image, Center, Spinner } from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { MdExtension, MdWeb } from 'react-icons/md';
import Ledger from '../assets/icons/Ledger.png';
import MultiversX from '../assets/icons/MultiversX.png';
import { walletConnectV2ProjectId } from '../blockchain/config';
import { useEffect } from 'react';
import { DiscordUser } from '../shared/DiscordUser';

const Unlock = (props: { userId?: string; username?: string; avatar?: string }) => {
    const commonProps = {
        nativeAuth: true,
        redirectAfterLogin: false,
    };

    useEffect(() => {
        console.log(props);
    }, [props]);

    return (
        <Center height="100vh" p={5}>
            <Stack spacing={8} alignItems="center">
                <Stack spacing={4} alignItems="center">
                    <Text fontWeight={500} textShadow="0 0 4px rgb(0 0 0 / 30%)" lineHeight="20px" textAlign="center">
                        Connect your wallet in order to link your{' '}
                        <Text as="span" color="discord" fontWeight={600}>
                            Discord
                        </Text>{' '}
                        profile.
                    </Text>

                    {!props ? <Spinner /> : <DiscordUser {...props} />}
                </Stack>

                <Stack spacing={3} width="282px">
                    <ExtensionLoginButton buttonClassName="Login-Button" {...commonProps}>
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                            <Box display="flex" alignItems="center">
                                <Box mb="1px" pl="2px" width="32px">
                                    <MdExtension fontSize="19px" />
                                </Box>
                                <Text fontSize="16px">Browser Extension</Text>
                            </Box>

                            <ChevronRightIcon color="#f5f5f5db" boxSize={6} />
                        </Box>
                    </ExtensionLoginButton>

                    <WebWalletLoginButton buttonClassName="Login-Button" callbackRoute="/" {...commonProps}>
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                            <Box display="flex" alignItems="center">
                                <Box width="32px">
                                    <MdWeb fontSize="21px" />
                                </Box>
                                <Text fontSize="16px">MultiversX Web Wallet</Text>
                            </Box>

                            <ChevronRightIcon color="#f5f5f5db" boxSize={6} />
                        </Box>
                    </WebWalletLoginButton>

                    <WalletConnectLoginButton
                        buttonClassName="Login-Button"
                        {...commonProps}
                        {...(walletConnectV2ProjectId
                            ? {
                                  isWalletConnectV2: true,
                              }
                            : {})}
                    >
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                            <Box display="flex" alignItems="center">
                                <Box width="32px" pl="2px">
                                    <Image src={MultiversX} alt="MultiversX" width="18px" height="18px" />
                                </Box>
                                <Text fontSize="16px">xPortal App</Text>
                            </Box>

                            <ChevronRightIcon color="#f5f5f5db" boxSize={6} />
                        </Box>
                    </WalletConnectLoginButton>

                    <LedgerLoginButton buttonClassName="Login-Button" {...commonProps}>
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                            <Box display="flex" alignItems="center">
                                <Box width="32px" pl="3px">
                                    <Image src={Ledger} alt="Ledger" width="16px" height="16px" />
                                </Box>
                                <Text fontSize="16px">Ledger</Text>
                            </Box>

                            <ChevronRightIcon color="#f5f5f5db" boxSize={6} />
                        </Box>
                    </LedgerLoginButton>
                </Stack>
            </Stack>
        </Center>
    );
};

export default Unlock;
