import axios from 'axios';
import { BOT_TOKEN, GATEWAY_URL } from '../blockchain/config';
import _ from 'lodash';

export const getShortAddress = (address: string, size = 4) => `${address.slice(0, size)}...${address.slice(-size)}`;

export const getUsername = async (address: string): Promise<string> => {
    let result = getShortAddress(address);

    const response = await axios.get(`address/${address}/username`, {
        baseURL: GATEWAY_URL,
    });

    const username: string | undefined = response?.data?.data?.username;

    if (username) {
        result = username.slice(0, username.indexOf('.elrond'));
    }

    return result;
};

// export const fetchDiscordUser = async (userId: string) => {
//     try {
//         const response = await axios.get(`https://discord.com/api/v10/users/${userId}`, {
//             headers: {
//                 Authorization: `Bot ${BOT_TOKEN}`,
//             },
//         });
//         return response.data;
//     } catch (error) {
//         console.error('Error fetching user', error);
//     }
// };
