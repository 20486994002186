import './global.scss';
import ReactDOM from 'react-dom/client';
import App from './App';
import { ColorModeScript } from '@chakra-ui/react';
import { EnvironmentsEnum } from '@multiversx/sdk-dapp/types';
import { BrowserRouter } from 'react-router-dom';
import { apiTimeout, walletConnectV2ProjectId, API_URL } from './blockchain/config';
import { DappProvider } from '@multiversx/sdk-dapp/wrappers';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <BrowserRouter>
        <DappProvider
            environment={EnvironmentsEnum.mainnet}
            customNetworkConfig={{
                name: 'customConfig',
                apiTimeout,
                walletConnectV2ProjectId,
                apiAddress: API_URL,
            }}
        >
            <ColorModeScript initialColorMode="dark" />
            <App />
        </DappProvider>
    </BrowserRouter>
);
