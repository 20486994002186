import { Image, Text, Stack, Center } from '@chakra-ui/react';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';
import { getShortAddress } from '../services/helpers';
import MultiversX from '../assets/icons/MultiversX_Color.png';

function Wallet() {
    const { address } = useGetAccountInfo();

    return (
        <Stack direction="row" alignItems="center" backgroundColor="#272727" px={3} py={1} borderRadius="18px">
            <Center borderRadius="50%" w="28px" backgroundColor="#111" p="5px">
                <Image src={MultiversX} alt="Avatar" />
            </Center>
            <Text>{getShortAddress(address)}</Text>
        </Stack>
    );
}

export default Wallet;
