export const walletConnectV2ProjectId = 'eb65c567ca5b5122e7cac31ab7c3568b';

export const apiTimeout = 30000;

export const API_URL = 'https://api.multiversx.com';
export const GATEWAY_URL = 'https://gateway.multiversx.com';

export const HOMEX_API = 'https://api.homex.quest';

export const BOT_TOKEN = 'MTE4ODkzMzQ4NzE1MjA3NDgwNQ.GbCH-g.0J7vI_tP4ILJf7qH2fr2mG1w5FCTRJPytkC2hY';
