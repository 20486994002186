import { ThemeConfig, extendTheme } from '@chakra-ui/react';

const config: ThemeConfig = {
    initialColorMode: 'dark',
    useSystemColorMode: false,
};

const colors = {
    primary: '#de4554',
    dark: '#1c1c1c',
    brightWheat: '#ffe1bb',
    energyBright: '#50a3f2',
    discord: '#738ADB',
};

const fonts = {
    body: "'Space Grotesk', sans-serif",
    heading: "'Space Grotesk', sans-serif",
    mono: "'Space Grotesk', sans-serif",
};

const styles = {
    global: {
        body: {
            color: 'whitesmoke',
            bg: 'dark',
        },
    },
};

const layerStyles = {
    layout: {
        width: '1300px',
        px: 4,
    },
    header1: {
        textTransform: 'uppercase',
        fontSize: '20px',
        fontWeight: 600,
        letterSpacing: '0.75px',
    },
    header2: {
        textTransform: 'uppercase',
        fontSize: '18px',
        fontWeight: 600,
        letterSpacing: '0.75px',
    },
};

const breakpoints = {
    sm: '696px',
    md: '960px',
    lg: '1736px',
    xl: '2026px',
    '2xl': '2436px',
};

export const theme = extendTheme({
    config,
    colors,
    fonts,
    styles,
    layerStyles,
    breakpoints,
});
